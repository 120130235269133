import MainLayout from '../components/layouts/main-layout';
import Image from 'next/image';
import HeroImage from '../public/img/hero/404_1920_640.jpg';
import Head from 'next/head';

export default function Custom404() {
    return (
        <MainLayout>
            <Head>
                <title>404 Error | Solvar</title>
            </Head>
            <div className="pt-[102px] lg:pt-[124px]">
                <div className="hidden sm:block bg-white h-[290px] md:h-[380px] lg:h-[496px] xl:h-[640px] relative">
                    <Image
                        src={ HeroImage }
                        alt="Hero Image"
                        layout="fill"
                        objectFit="cover"
                        quality="100"
                        priority={ true }
                        placeholder="blur"
                    />
                </div>
                <div className="sm:hidden bg-white relative">
                    <Image
                        src={ HeroImage }
                        alt="Hero Image"
                        layout="responsive"
                        objectFit="fill"
                        quality="100"
                        priority={ true }
                        placeholder="blur"
                    />
                </div>
                <div className="bg-grey">
                    <div className="container text-center md:text-left py-20">
                        404 Error
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container py-60">
                        <h1 className="text-bb pb-40">Page not found</h1>
                        <p>The page you requested cannot be found.</p>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}